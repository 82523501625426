import { useState } from 'react';
import { BrandedAssetData, CollectionsData } from '../../../../global/interfaces';
import { AssetType } from '../../../../global/consts';
import { useReneQuery } from '../../../../hooks';
import { isObjEmpty } from '../../../../utils';
import { createCollectionsQuery } from '../../../../global/gql/queries';
import Icon from '../../../../components/Icon/Icon';
import Checkbox from '../../../../components/checkbox/checkbox';
import LoadingFallback from '../../../../components/loading-fallback/loading-fallback';
import './branded-asset-catalog.scss';

const BrandedAssetCatalog = ({ setCloseModal }: { setCloseModal: () => void }) => {
  const [selectedAssets, setSelectedAssets] = useState<{ [key: string]: BrandedAssetData }>({});
  const { data, loading } = useReneQuery<{ Collections: CollectionsData }>(createCollectionsQuery(AssetType.BRANDED), {
    variables: { collectionId: '32f0aa31-11c9-444f-a1b0-27c7cb55d6ca' },
  });

  const handleAddBrandedAsset = (asset: BrandedAssetData) => {
    setSelectedAssets((prev) => {
      const updatedAssets = { ...prev };
      if (updatedAssets[asset.brandedAssetId]) {
        delete updatedAssets[asset.brandedAssetId]; // Remove asset if already selected
      } else {
        updatedAssets[asset.brandedAssetId] = asset; // Add asset if not selected
      }
      return updatedAssets;
    });
  };
  const isSelected = !isObjEmpty(selectedAssets);
  return (
    <div className="branded-asset-catalog">
      <div className="branded-asset-catalog__heading">
        <h2>Branded Assets</h2>
        <button type="button" onClick={setCloseModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <div className={`branded-asset-catalog__main`}>
        {!loading ? (
          data?.Collections.items[0]?.brandedAssets.items?.map((asset: BrandedAssetData) => (
            <button className="branded-asset-catalog__main_asset" onClick={() => handleAddBrandedAsset(asset)}>
              <Checkbox value={!!selectedAssets[asset.brandedAssetId]} setValue={() => {}} />
              <img src={asset.image.url} alt="branded asset" />
              <div className="branded-asset-catalog__main_asset_info">
                <p className="branded-asset-catalog__main_asset_info_brand">{asset.brand.name}</p>
                <p className="branded-asset-catalog__main_asset_info_name">{asset.name}</p>
                <p className="branded-asset-catalog__main_asset_info_impressions">
                  Total: <span>1500</span> <Icon name="eye" size={16} />
                </p>
              </div>
            </button>
          ))
        ) : (
          <LoadingFallback />
        )}
        {isSelected && (
          <div className="branded-asset-catalog__main_add">
            <p>{Object.keys(selectedAssets).length} selected</p>
            <button className="secondary-btn">Add</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BrandedAssetCatalog;
