import { useCallback, useEffect, useState } from 'react';
import { useLazyReneQuery, useReneMutation } from '../../../hooks';
import { format } from 'date-fns';
import { CampaignData, CampaignsData, Event, UserData } from '../../../global/interfaces';
import { CAMPAIGNS_SEARCH_QUERY, GET_AD_CAMPAIGNS_QUERY, GET_USER_QUERY } from '../../../global/gql/queries';
import { UPSERT_AD_CAMPAIGN_MUTATION } from '../../../global/gql/mutations';
import { UserRole } from '../../../global/consts';
import { useReneQuery } from '../../../hooks/useReneQuery';
import { checkDateRangeStatus, isRoleAllowed } from '../../../utils';
import Icon from '../../../components/Icon/Icon';
import Modal from '../../../components/modal/modal';
import Search from '../../../components/search/search';
import CampaignModal from '../../../components/modal/campaign-modal/campaign-modal';
import LoadingFallback from '../../../components/loading-fallback/loading-fallback';
import SmallDashboardBar from '../../../components/small-dashboard-bar/small-dashboard-bar';

import typeBanner from '../../../global/images/banner.svg';

import './organization-campaigns-page.scss';

const OrganizationCampaignsPage = () => {
  const [adCampaignSearchTerm, setSearchTerm] = useState('');

  const [isNewCampaignModalOpen, setIsNewCampaignModalOpen] = useState<boolean>(false);

  const { data: user } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);
  const {
    data,
    loading: campaignsLoading,
    refetch,
  } = useReneQuery<{ AdCampaigns: CampaignsData } | undefined>(GET_AD_CAMPAIGNS_QUERY);

  const [search, { data: searchCampaigns, loading: searchLoading }] = useLazyReneQuery<{
    AdCampaignSearch: CampaignsData;
  }>(CAMPAIGNS_SEARCH_QUERY);

  const [upsertCampaign] = useReneMutation(UPSERT_AD_CAMPAIGN_MUTATION, {
    onCompleted() {
      refetch();
    },
  });

  useEffect(() => {
    if (adCampaignSearchTerm) {
      search({ variables: { adCampaignSearchTerm } });
    }
  }, [adCampaignSearchTerm, search]);

  const handleSearch = useCallback((data: string) => {
    setSearchTerm(data);
  }, []);

  const handleCleanCampaign = (e: Event['Button']) => {
    e.stopPropagation();
    e.preventDefault();
    upsertCampaign({
      variables: {
        isDemo: false,
        adCampaignId: e.currentTarget.name,
      },
    });
  };

  const isUserAllowed = isRoleAllowed(user?.User.role as UserRole, [UserRole.ADVERTISER]);
  const showCampaignData =
    adCampaignSearchTerm && searchCampaigns ? searchCampaigns.AdCampaignSearch.items : data?.AdCampaigns.items;

  const prepackCampaigns = showCampaignData?.reduce<{
    future: CampaignData[];
    passed: CampaignData[];
    present: CampaignData[];
  }>(
    (prev, current) => {
      const status = checkDateRangeStatus(current.ads?.items?.length, current.startDate, current.endDate);

      if (status === 'Finished') {
        prev.passed.push(current);
      } else if (status === 'In Progress') {
        prev.present.push(current);
      } else if (status === 'Scheduled' || status === 'Draft') {
        prev.future.push(current);
      }

      return prev;
    },
    {
      future: [],
      passed: [],
      present: [],
    },
  );

  const singleCampaign = (campaign: CampaignData) => (
    <SmallDashboardBar
      id={campaign.adCampaignId}
      key={campaign.adCampaignId}
      linkTo={`${campaign.adCampaignId}`}
      name={campaign.name}
      imageUrl={typeBanner}
      description={campaign?.brand?.name}
    >
      <div className="small-dashboard-bar__campaign">
        <p className="small-dashboard-bar__campaign_time">
          <span className={checkDateRangeStatus(campaign.ads?.items?.length, campaign.startDate, campaign.endDate)}>
            {checkDateRangeStatus(campaign.ads?.items?.length, campaign.startDate, campaign.endDate)}
          </span>
          {format(new Date(campaign.startDate), 'MMM dd, yyyy')} - {format(new Date(campaign.endDate), 'MMM dd, yyyy')}
        </p>
        <div className="small-dashboard-bar__campaign_stats">
          <p className={`${!campaign.ads?.items?.length ? 'small-dashboard-bar__campaign_stats--no-ads' : ''}`}>
            {campaign.ads?.items?.length ? `${campaign.ads.items.length} Ads` : 'No Ads'}
          </p>
          <p>
            200k
            <Icon name="eye" size={16} />
          </p>
          <p>${80}</p>
        </div>
        {campaign.isDemo && user?.User?.isDemo ? (
          <button
            name={campaign.adCampaignId}
            className="primary-btn small-dashboard-bar__campaign_clean"
            onClick={handleCleanCampaign}
          >
            X
          </button>
        ) : null}
      </div>
    </SmallDashboardBar>
  );

  return (
    <div className="organization-campaigns-page">
      <div className="organization-campaigns-page__heading">
        <div className="organization-campaigns-page__heading_title">
          <h1>
            Campaigns <span>{showCampaignData?.length}</span>
          </h1>
          {/* <div>
            <p>
              <span> {showCampaignData?.items[0]?.stats?.impressions}</span> Impressions
            </p>
            <p>
              <span>{showCampaignData?.items[0]?.stats?.interactions}</span> Interactions
            </p>
          </div> */}
        </div>
        <div className="organization-campaigns-page__heading_actions">
          <Search callback={handleSearch} apiSearch />
          {isUserAllowed && (
            <button
              type="button"
              className="primary-btn organization-campaigns-page__heading_actions_new"
              onClick={() => setIsNewCampaignModalOpen(true)}
            >
              <Icon name="plus" size={24} />
              <p>New</p>
            </button>
          )}
        </div>
      </div>
      <div className="organization-campaigns-page__content">
        <div className="organization-campaigns-page__content_campaigns">
          {campaignsLoading || searchLoading ? (
            <LoadingFallback />
          ) : (
            <>
              {prepackCampaigns?.future?.map((campaign) => singleCampaign(campaign))}
              <h3>Active campaigns</h3>
              {prepackCampaigns?.present?.map((campaign) => singleCampaign(campaign))}
              <h3>Past campaigns</h3>
              {prepackCampaigns?.passed?.map((campaign) => singleCampaign(campaign))}
            </>
          )}
        </div>
      </div>
      <Modal isOpen={isNewCampaignModalOpen}>
        <CampaignModal setCloseModal={() => setIsNewCampaignModalOpen(false)} />
      </Modal>
    </div>
  );
};

export default OrganizationCampaignsPage;
