import { Event, UserData } from '../../global/interfaces';
import avatar from '../../global/images/avatar.webp';
import Icon from '../Icon/Icon';
import Statistics from '../statistics/statistics';
import './player-card.scss';

interface Props {
  user: UserData | undefined;
  handleSelectPlayer?: (id: string) => void;
}

const PlayerCard: React.FC<Props> = ({ user, handleSelectPlayer }) => {
  const handleSelectedPlayer = (e: Event['Div']) => {
    if (handleSelectPlayer) handleSelectPlayer(e.currentTarget.id);
  };

  return (
    <div className="player-card" id={user?.userId} onClick={handleSelectedPlayer}>
      <div className="player-card__bio">
        <div className="player-card__bio_img">
          <img src={user?.image?.url || avatar} alt="player" />
        </div>
        <div className="player-card__bio_name">
          <p>{`${user?.data?.firstName} ${user?.data?.lastName}`}</p>
          <p>{user?.email}</p>
        </div>
      </div>
      <div className="player-card__social">
        {user?.externalAccounts && (
          <div className="player-card__social_accounts">
            {user.externalAccounts.discordId && (
              <a href={user?.externalAccounts?.discordId}>
                <Icon name="discord" />
              </a>
            )}
          </div>
        )}
        <Statistics data={{ assets: user?.stats?.assets, value: `$${user?.stats.value}` }} />
      </div>
    </div>
  );
};

export default PlayerCard;
